import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SpinnerLoading } from 'src/shared/components';
import Survey from '../components/Survey/Survey';
import { LinkItems } from '../components/WithNav/LinkItems';
import AuthGuard, { AuthGuardProps } from './guards/AuthGuard/AuthGuard';
import UnAuthGuard from './guards/UnAuthGuard/UnAuthGuard';

const LazyRequestsModule = React.lazy(() => import('../../modules/RequestsModule/RequestsModule'));
const LazyUsersModule = React.lazy(() => import('../../modules/UsersModule/UsersModule'));
const LazyTeamsModule = React.lazy(() => import('../../modules/TeamsModule/TeamsModule'));
const LazyCompanyModule = React.lazy(() => import('../../modules/CompanyModule/CompanyModule'));
const LazyHomeModule = React.lazy(() => import('../../modules/HomeModule/HomeModule'));
const LazyNdaCompanyModule = React.lazy(() => import('../../modules/NdaCompanyModule/NdaCompanyModule'));
const LazyNdaGertecModule = React.lazy(() => import('../../modules/NdaGertecModule/NdaGertecModule'));
const LazyMigrateModule = React.lazy(() => import('../../modules/MigrateModule/MigrateModule'));
const LazyClientModule = React.lazy(() => import('../../modules/ClientModule/ClientModule'));
const LazyDashboardModule = React.lazy(() => import('../../modules/DashboardModule/DashboardModule'));
const LazyDocumentsModule = React.lazy(() => import('../../modules/DocumentsModule/DocumentsModule'));
const LazyServicesModule = React.lazy(() => import('../../modules/ServicesModule/ServicesModule'));
const LazyDevicesModule = React.lazy(() => import('../../modules/DevicesModule/DevicesModule'));

const getLinkByRouteName = (routeName: string) => {
  return LinkItems.find((l) => l.name === routeName)!;
};

function WrapperComponent({ component, linkConfig }: AuthGuardProps) {
  return (
    <AuthGuard
      linkConfig={linkConfig}
      component={
        <Suspense fallback={<SpinnerLoading />}>
          <Survey />
          {component}
        </Suspense>
      }
    />
  );
}

const AppRouter: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<UnAuthGuard />} />
      <Route
        path="home/*"
        element={<WrapperComponent linkConfig={getLinkByRouteName('home')} component={<LazyHomeModule />} />}
      />
      <Route
        path="requests/*"
        element={<WrapperComponent linkConfig={getLinkByRouteName('requests')} component={<LazyRequestsModule />} />}
      />
      <Route
        path="users/*"
        element={<WrapperComponent linkConfig={getLinkByRouteName('users')} component={<LazyUsersModule />} />}
      />
      <Route
        path="teams/*"
        element={<WrapperComponent linkConfig={getLinkByRouteName('teams')} component={<LazyTeamsModule />} />}
      />
      <Route
        path="company/*"
        element={<WrapperComponent linkConfig={getLinkByRouteName('company')} component={<LazyCompanyModule />} />}
      />
      <Route
        path="nda-companies/*"
        element={
          <WrapperComponent linkConfig={getLinkByRouteName('nda-companies')} component={<LazyNdaCompanyModule />} />
        }
      />
      <Route
        path="nda-gertec/*"
        element={<WrapperComponent linkConfig={getLinkByRouteName('nda-gertec')} component={<LazyNdaGertecModule />} />}
      />
      <Route
        path="migrate/*"
        element={
          <WrapperComponent linkConfig={getLinkByRouteName('migrateCompanies')} component={<LazyMigrateModule />} />
        }
      />
      <Route
        path="clients/*"
        element={<WrapperComponent linkConfig={getLinkByRouteName('clients')} component={<LazyClientModule />} />}
      />
      <Route
        path="documents/*"
        element={<WrapperComponent linkConfig={getLinkByRouteName('documents')} component={<LazyDocumentsModule />} />}
      />
      <Route
        path="services/*"
        element={<WrapperComponent linkConfig={getLinkByRouteName('services')} component={<LazyServicesModule />} />}
      />
      <Route
        path="dashboard/*"
        element={<WrapperComponent linkConfig={getLinkByRouteName('dashboard')} component={<LazyDashboardModule />} />}
      />

      <Route
        path="devices/*"
        element={<WrapperComponent linkConfig={getLinkByRouteName('devices')} component={<LazyDevicesModule />} />}
      />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRouter;

import { Building, Community, GraphUp, Group, Home, PasteClipboard, Shield, VerifiedUser } from 'iconoir-react';
import { IconType } from 'react-icons';
import { BsFileEarmarkCheckFill } from 'react-icons/bs';
import { MdOutlineNoteAdd } from 'react-icons/md';
import { APIModels } from 'src/shared/api';
import { IconNoirType } from 'src/shared/types/icons';

const { TypeUser } = APIModels;

export interface LinkItemProps {
  name: string;
  icon: IconType | IconNoirType;
  url: string;
  allowedUserTypes: APIModels.TypeUser[];
  canAccess: (user: APIModels.IAuthUserDTO) => boolean;
  isHidden?: boolean;
}

export const LinkItems: Array<LinkItemProps> = [
  {
    name: 'home',
    icon: Home,
    url: '/home',
    allowedUserTypes: [
      TypeUser.company_owner,
      TypeUser.company_administrator,
      TypeUser.company_common,
      TypeUser.gertec_master
    ],
    canAccess: () => true
  },
  {
    name: 'dashboard',
    icon: GraphUp,
    url: '/dashboard',
    allowedUserTypes: [TypeUser.gertec_master],
    canAccess: () => true
  },
  {
    name: 'requests',
    icon: PasteClipboard,
    url: '/requests',
    allowedUserTypes: [TypeUser.company_owner, TypeUser.company_administrator, TypeUser.company_common],
    canAccess: () => true
  },
  {
    name: 'teams',
    icon: Community,
    url: '/teams',
    allowedUserTypes: [TypeUser.company_owner, TypeUser.company_administrator],
    canAccess: () => true
  },
  {
    name: 'users',
    icon: VerifiedUser,
    url: '/users',
    allowedUserTypes: [TypeUser.company_owner, TypeUser.company_administrator],
    canAccess: () => true
  },
  {
    name: 'company',
    icon: Building,
    url: '/company',
    allowedUserTypes: [TypeUser.company_owner, TypeUser.company_administrator],
    canAccess: () => true
  },
  {
    name: 'nda-companies',
    icon: Shield,
    url: '/nda-companies',
    allowedUserTypes: [TypeUser.company_owner, TypeUser.company_administrator],
    canAccess: (user) => user.info.hasAccessToNda
  },
  {
    name: 'clients',
    icon: Group,
    url: '/clients',
    allowedUserTypes: [TypeUser.gertec_master],
    canAccess: () => true
  },
  {
    name: 'nda-gertec',
    icon: BsFileEarmarkCheckFill,
    url: '/nda-gertec',
    allowedUserTypes: [TypeUser.gertec_master],
    canAccess: () => true
  },
  {
    name: 'migrateCompanies',
    icon: MdOutlineNoteAdd,
    url: '/migrate',
    allowedUserTypes: [TypeUser.gertec_master],
    canAccess: () => true
  },
  {
    name: 'documents',
    icon: Home,
    url: '/documents',
    allowedUserTypes: [TypeUser.company_owner, TypeUser.company_common, TypeUser.company_administrator],
    canAccess: () => true,
    isHidden: true
  },
  {
    name: 'services',
    icon: Home,
    url: '/services',
    allowedUserTypes: [TypeUser.company_owner, TypeUser.company_common, TypeUser.company_administrator],
    canAccess: () => true,
    isHidden: true
  },
  {
    name: 'devices',
    icon: Home,
    url: '/devices',
    allowedUserTypes: [
      TypeUser.company_owner,
      TypeUser.company_common,
      TypeUser.company_administrator,
      TypeUser.gertec_master
    ],
    canAccess: () => true,
    isHidden: true
  }
];

import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { useI18n } from 'src/i18n';
import { APIModels } from 'src/shared/api';
import { TypeUser } from 'src/shared/api/models';
import clip from 'src/shared/assets/imgs/clip.svg';
import hammer from 'src/shared/assets/imgs/hammer.svg';
import info from 'src/shared/assets/imgs/info.svg';
import simpleCart from 'src/shared/assets/imgs/simple-cart.svg';
import { Icons } from 'src/shared/components';

interface LinkNavOptionProps {
  url: string;
  label: string;
  isExternal: boolean;
}

export interface NavOptionsWithSubOptionsProps {
  allowedUserTypes: APIModels.TypeUser[];
  links: Array<LinkNavOptionProps>;
  label: string;
  component: (label: string, options: Array<LinkNavOptionProps>) => React.ReactNode;
}

export interface NavOptionsProps extends LinkNavOptionProps {
  allowedUserTypes: APIModels.TypeUser[];
  icon: string;
}

export const NavOptions: Array<NavOptionsProps | NavOptionsWithSubOptionsProps> = [
  {
    isExternal: false,
    label: 'app.navbar.documentation',
    url: '/documents',
    icon: clip,
    allowedUserTypes: [TypeUser.company_owner, TypeUser.company_common, TypeUser.company_administrator]
  },
  {
    isExternal: true,
    label: 'app.navbar.support',
    url: `${process.env.REACT_APP_JIRA_DOMAIN}/servicedesk/customer/portals`,
    icon: hammer,
    allowedUserTypes: [
      TypeUser.company_owner,
      TypeUser.company_common,
      TypeUser.company_administrator,
      TypeUser.gertec_master
    ]
  },
  {
    isExternal: true,
    label: 'app.navbar.forum',
    url: `${process.env.REACT_APP_FORUM_GERTEC}`,
    icon: info,
    allowedUserTypes: [
      TypeUser.company_owner,
      TypeUser.company_common,
      TypeUser.company_administrator,
      TypeUser.gertec_master
    ]
  },
  {
    label: 'app.navbar.services',
    component: (label, options) => <OptionServices label={label} options={options} key={label} />,
    links: [
      {
        isExternal: false,
        label: 'app.navbar.publishing',
        url: '/services/publishing'
      },
      {
        isExternal: false,
        label: 'app.navbar.smartstore',
        url: '/services/smartstore'
      },
      {
        isExternal: false,
        label: 'app.navbar.mymdm',
        url: '/services/mymdm'
      }
    ],
    allowedUserTypes: [TypeUser.company_owner, TypeUser.company_common, TypeUser.company_administrator]
  },
  {
    isExternal: false,
    label: 'app.navbar.store',
    url: '/devices',
    icon: simpleCart,
    allowedUserTypes: [
      TypeUser.company_owner,
      TypeUser.company_common,
      TypeUser.company_administrator,
      TypeUser.gertec_master
    ]
  }
];

function OptionServices({ label, options }: { label: string; options: Array<LinkNavOptionProps> }) {
  const { translate } = useI18n();
  return (
    <Menu>
      <MenuButton
        as={Button}
        leftIcon={<Icons.Rocket w="28px" h="28px" />}
        h="65px"
        bg="transparent"
        fontWeight="normal"
        _hover={{
          bg: 'rgba(255, 255, 255, 0.4)',
          textDecoration: 'underline'
        }}
        borderRadius="0"
      >
        {translate(label)}
      </MenuButton>
      <MenuList>
        {options.map((o) => (
          <MenuItem key={o.label} as="a" href={o.url}>
            {translate(o.label)}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}

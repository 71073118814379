export const keysUserEnUs = {
  'users.view.home': 'Home',
  'users.view.subtitle': 'Manage people and permissions',
  'users.guard.userId': 'User of id {{userId}} cannot be found',
  'users.guard.userId.description': 'Check the id and try again',

  'users.editUser': 'Edit user',
  'user.updatePicture': 'Update picture',
  'user.removePicture': 'Remove picture',
  'user.accessLevel.director': 'Director',
  'user.accessLevel.manager': 'Manager',
  'user.accessLevel.contributor': 'Contributor',
  'user.birthday': 'Birthday',

  'users.tab.approved': 'Approved',
  'users.tab.pending': 'Pending',
  'users.tab.rejected': 'Rejected',

  'users.table.permission': 'Permission',
  'users.table.corporateEmail': 'Corporate email',
  'users.table.defaultoption.selectinput': 'All',
  'users.table.type': 'Function',
  'users.table.type.0': 'Developer',
  'users.table.type.1': 'Administrator',
  'users.table.type.3': 'Owner',

  'users.toast.approved': 'User approved',
  'users.toast.rejected': 'User rejected',

  'users.modal.delete.title': 'Delete user',
  'users.modal.delete.username': '<0>Are you sure you want to delete <1>{{username}}</1> ?</0>',

  'users.modal.invite.placeholder': 'Type emails here',
  'users.modal.invite.guidelines':
    'To add an email, type on input field and then click on select option OR press Enter',
  'users.modal.invite.addMessage': 'Add',
  'users.modal.invite.message': 'Invite people to be part of your company:',
  'users.modal.invite.button': 'Invite',
  'users.modal.invite.title': 'Invite people',
  'users.modal.invite.toast.title': 'Sent !',
  'users.modal.invite.domains': 'Registered domains:',
  'users.modal.invite.domainInvalid': 'Domain not registered',
  'users.modal.invite.send': 'Send invitation',

  'users.modal.reject.title': 'Reject User',
  'users.modal.reject.subtitle': 'Justify below the reason for your rejection:',
  'users.modal.reject.placeholder': 'Type here:',
  'users.modal.reject.confirm': 'Confirm',
  'users.modal.input.validation.maximum': 'The reason must have a maximum of 250 characters',
  'users.modal.input.validation.minimum': 'Reason must be at least 5 characters long',

  'users.modal.input.name.validation.maximum': 'The name must have a maximum of 190 characters',
  'users.modal.input.name.placeholder': 'Enter your name',
  'users.modal.input.teams.placeholder': "Inform the user's team",
  'users.modal.input.isAdm': 'Is this User an Administrator ?',

  'users.modal.update.roles.database_adm': 'Database Admin',
  'users.modal.update.roles.network_adm': 'Network Admin',
  'users.modal.update.roles.system_adm': 'System Admin',
  'users.modal.update.roles.cybersecurity_adm': 'Cybersecurity Admin',
  'users.modal.update.roles.software_analyst': 'Software Analyst',
  'users.modal.update.roles.business_analyst': 'Business Analyst',
  'users.modal.update.roles.network_analyst': 'Network Analyst',
  'users.modal.update.roles.test_analyst': 'Test Analyst',
  'users.modal.update.roles.software_developer': 'Software Developer',
  'users.modal.update.roles.web_developer': 'Web Developer',
  'users.modal.update.roles.it_director': 'IT Director',
  'users.modal.update.roles.rd_director': 'RD Director',
  'users.modal.update.roles.computer_engineer': 'Computer Engineer',
  'users.modal.update.roles.qa_engineer': 'QA engineer',
  'users.modal.update.roles.hardware_engineer': 'Hardware Engineer',
  'users.modal.update.roles.network_engineer': 'Network Engineer',
  'users.modal.update.roles.software_develop_manager': 'Software Develop Manager',
  'users.modal.update.roles.it_infrastructure_manager': 'IT Infrastructure Manager',
  'users.modal.update.roles.it_project_manager': 'IT Project Manager',
  'users.modal.update.roles.cybersecurity_manager': 'Cybersecurity Manager',
  'users.modal.update.roles.it_support_manager': 'IT Support Manager',
  'users.modal.update.roles.system_operator': 'System Operator',
  'users.modal.update.roles.it_programmer': 'IT Programmer',
  'users.modal.update.roles.programmer_technician': 'Programmer Technician',
  'users.modal.update.roles.qa_technician': 'QA Technician',
  'users.modal.update.roles.others': 'Others',
  'users.modal.update.roles.representative': 'Legal Representative'
};

export const keysNdasEnUs = {
  'ndaCompany.view.subtitle': 'Manage people, teams and permissions',
  'ndaCompany.button.add': 'Add',
  'ndaCompany.modal.title': 'Send NDA - Non-Disclosure Agreement',
  'ndaCompany.modal.subtitle': 'To proceed with your request, you need to sign a non-disclosure agreement with Gertec.',
  'ndaCompany.modal.descriptionSteps': 'There are 2 simple steps:',
  'ndaCompany.modal.details': 'NDA Details',
  'ndaCompany.modal.description.project': 'Project description',
  'ndaCompany.modal.download.title': '1. Download',
  'ndaCompany.modal.download.description': 'You need to download the file by clicking on the link below.',
  'ndaCompany.modal.download.descriptionPartial': 'agreement download',

  'ndaCompany.modal.download.placeholder': 'Click here to download file',
  'ndaCompany.modal.upload.title': '2. Upload',
  'ndaCompany.modal.upload.description': 'Upload the document with you signature.',
  'ndaCompany.modal.upload.descriptionPartial': 'according to your signature',

  'ndaCompany.modal.upload.placeholder': 'Click here to select file',
  'ndaCompany.modalSend.success': 'NDA sent successfully.',
  'ndaCompany.modalSend.loadingButton': 'Sending...',

  'ndaCompany.modalReason.title': 'Reason',

  'ndaCompany.table.title': 'Status NDAs',
  'ndaCompany.table.column.responsible': 'Responsible',
  'ndaCompany.table.column.email': 'Corporate e-mail',
  'ndaCompany.table.column.requestDate': 'Request date',
  'ndaCompany.table.column.returnDate': 'Return date',
  'ndaCompany.table.column.status': 'Status',
  'ndaCompany.table.column.actions': 'Actions',

  'ndaCompany.status.0': 'In review',
  'ndaCompany.status.1': 'Approved',
  'ndaCompany.status.2': 'Disapproved',

  'ndaCompany.table.action.tooltip': 'See reason',

  'ndaCompany.filter.search.placeholder': 'Search ',
  'ndaCompany.filter.status.all': 'All',

  'ndaGertec.view.subtitle': 'Manage people, teams and permissions',
  'ndaGertec.button.update': 'Update',

  'ndaGertec.table.company.column.documentId': 'CNPJ/TAXID',
  'ndaGertec.table.company.column.companyName': 'Company Name',
  'ndaGertec.table.company.column.legalRepresentative': 'Legal Representative',
  'ndaGertec.table.company.column.corporateEmail': 'Email',
  'ndaGertec.table.company.column.status': 'Status',
  'ndaGertec.table.company.column.updateDate': 'Update date',
  'ndaGertec.table.company.column.actions': 'Actions',

  'ndaGertec.table.pending.column.documentId': 'CNPJ/TAXID',
  'ndaGertec.table.pending.column.companyName': 'Company Name',
  'ndaGertec.table.pending.column.legalRepresentative': 'Legal Representative',
  'ndaGertec.table.pending.column.corporateEmail': 'Email',
  'ndaGertec.table.pending.column.updateDate': 'Request date',
  'ndaGertec.table.pending.column.actions': 'Actions',

  'ndaGertec.table.history.column.document': 'File name',
  'ndaGertec.table.history.column.version': 'Version',
  'ndaGertec.table.history.column.updateDate': 'Update date',
  'ndaGertec.table.history.column.changelog': 'Changelog',
  'ndaGertec.table.history.column.attachment': 'Attachment',

  'ndaGertec.tab.company': 'Company',
  'ndaGertec.tab.pending': 'Pending',
  'ndaGertec.tab.history': 'NDA History',

  'ndaGertec.filter.search.placeholder': 'Seach CNPJ or company',

  'ndaGertec.modal.updateNda.updateNda': 'Update NDA version',
  'ndaGertec.modal.updateNda.documentName': 'Document name',
  'ndaGertec.modal.updateNda.placeholderName': 'Enter a name for the document',
  'ndaGertec.modal.updateNda.version': 'Version',
  'ndaGertec.modal.updateNda.updateDate': 'Update date',
  'ndaGertec.modal.discard.title': 'New document version',

  'ndaGertec.modal.approval.title': 'Non-Disclosure Agreement',
  'ndaGertec.modal.approval.description': 'Does the attached document match the latest NDA version?',
  'ndaGertec.modal.approval.latestVersion': 'Latest Version of the NDA',
  'ndaGertec.modal.approval.file.placeholder': 'NDA_document_104.pdf',
  'ndaGertec.modal.approval.loadingButton': 'Loading...',
  'ndaGertec.modal.approval.approved': 'Succesfully approved NDA',
  'ndaGertec.modal.approval.disapproved': 'NDA rejected',
  'ndaGertec.modal.approval.action.approve': 'Approve',
  'ndaGertec.modal.approval.action.reject': 'Reject',
  'ndaGertec.modal.approval.action.discard': 'NDA - Company Info',
  'ndaGertec.modal.approval.description.approve': 'Do you want to approve this NDA?',
  'ndaGertec.modal.approval.description.reject': 'Do you want to reject this NDA?',
  'ndaGertec.modal.approval.description.discard': 'All the unsaved data will be lost.\nCotinue?',
  'ndaGertec.modal.approval.description.project': 'Project description',
  'ndaGertec.modal.approval.attached.document': 'Document Attached',

  'ndaGertec.modal.company.attached.document': 'Attached document',
  'ndaGertec.modal.company.last.version.nda': 'Latest version of the NDA'
};

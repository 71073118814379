import { keysClientPtBr } from './keys/ptBr/keys-client-pt-br';
import { keysDocumentPtBr } from './keys/ptBr/keys-document-pt-br';
import { keysMigratesPtBr } from './keys/ptBr/keys-migrates-pt-br';
import { keysNdasPtBr } from './keys/ptBr/keys-ndas-pt-br';
import { keysRequestPtBr } from './keys/ptBr/keys-request-pt-br';
import { keysServicesPtBr } from './keys/ptBr/keys-services-pt-br';
import { keysSurveyPtBr } from './keys/ptBr/keys-survey-pt-br';
import { keysUserPtBr } from './keys/ptBr/keys-user-pt-br';

export const ptBR = {
  'app.month.Jan': 'Jan',
  'app.month.Feb': 'Fev',
  'app.month.Mar': 'Mar',
  'app.month.Apr': 'Abr',
  'app.month.May': 'Mai',
  'app.month.Jun': 'Jun',
  'app.month.Jul': 'Jul',
  'app.month.Aug': 'Ago',
  'app.month.Sep': 'Set',
  'app.month.Oct': 'Out',
  'app.month.Nov': 'Nov',
  'app.month.Dec': 'Dez',
  'app.month.01': 'Jan',
  'app.month.02': 'Fev',
  'app.month.03': 'Mar',
  'app.month.04': 'Abr',
  'app.month.05': 'Mai',
  'app.month.06': 'Jun',
  'app.month.07': 'Jul',
  'app.month.08': 'Ago',
  'app.month.09': 'Set',
  'app.month.10': 'Out',
  'app.month.11': 'Nov',
  'app.month.12': 'Dez',

  'app.label.name': 'Nome',
  'app.label.id': 'Id',
  'app.label.accessLevel': 'Nível de acesso',
  'app.label.tags': 'Tags',
  'app.label.lastAccess': 'Último acesso',
  'app.label.email': 'E-mail',
  'app.label.username': 'Usuário',
  'app.label.password': 'Senha',
  'app.label.signIn': 'Entrar',
  'app.label.required': 'Obrigatório',
  'app.label.minLengthOf': 'Tamanho mínimo deve ser {{number}}',
  'app.label.yes': 'Sim',
  'app.label.no': 'Não',
  'app.label.language': 'Idioma',
  'app.label.theme': 'Tema',
  'app.label.actions': 'Ações',
  'app.label.type': 'Tipo',
  'app.label.reference': 'Referência',
  'app.label.summary': 'Resumo',
  'app.label.version': 'Versão',
  'app.label.next': 'Próximo',
  'app.label.finish': 'Finalizar',
  'app.label.loadingLink': 'Gerando link...',
  'app.label.preposition.to': 'a',
  'app.label.description': 'Descrição',
  'app.label.storeLink': 'Link para loja',
  'app.label.documentationLink': 'Link para documentação',
  'app.label.supportLink': 'Link para suporte',
  'app.label.devicePhoto': 'Imagem',
  'app.label.devicePhoto.placeholder': 'Selecione a imagem do dispositivo',
  'app.label.storeLink.button': 'Ir para Loja',

  'app.toast.successfulOperation': 'Operação realizada com sucesso.',

  'app.actions.signOut': 'Sair',
  'app.actions.edit': 'Editar',
  'app.actions.save': 'Salvar',
  'app.actions.cancel': 'Cancelar',
  'app.actions.send': 'Enviar',
  'app.actions.sending': 'Enviando...',
  'app.actions.delete': 'Deletar',
  'app.actions.back': 'Voltar',
  'app.actions.confirm': 'Confirmar',

  'app.result.status': 'Status',
  'app.result.wrongEmailFormat': 'Formato de e-mail inválido',
  'app.result.notfound': 'Não foram encontrados resultados',
  'app.result.status.2': 'Ativo',

  'app.form.validation.required': 'Campo obrigatório',
  'app.form.validation.emailInvalid': 'Email inválido',
  'app.form.validation.emailMinimalOne': 'Informe pelo menos um email',
  'app.form.validation.filetypeInvalid': 'O arquivo selecionado não é válido',
  'app.form.validation.selectFile': 'Selecione um arquivo',
  'app.form.validation.columnsInvalidSheet': 'Corrija o nome das seguintes colunas: {{columns}}.',

  'app.companies.size': 'Porte',
  'app.requests.serviceProject': 'Projeto de serviço',
  'app.requests.requester': 'Solicitante',

  'app.menus.home': 'Home',
  'app.menus.users': 'Pessoas',
  'app.menus.teams': 'Times',
  'app.menus.devices': 'Dispositivos',
  'app.menus.device': 'Dispositivo',
  'app.menus.users.role': 'Cargo',
  'app.menus.company': 'Perfil',
  'app.menus.requests': 'Tickets',
  'app.menus.nda-companies': 'NDA',
  'app.menus.nda-gertec': 'NDA',
  'app.menus.migrateCompanies': 'Migração de empresas',
  'app.menus.migrateTitle': 'Migração',
  'app.menus.clients': 'Clientes',
  'app.menus.dashboard': 'Dashboard',
  'app.navbar.selectNavbarOption': 'Escolha um conteúdo para acessar',
  'app.navbar.forum': 'Fórum',
  'app.navbar.support': 'Suporte',
  'app.navbar.documentation': 'Documentação',
  'app.navbar.services': 'Serviços',
  'app.navbar.publishing': 'Publicações',
  'app.navbar.smartstore': 'Smart Store',
  'app.navbar.mymdm': 'My MDM',
  'app.navbar.store': 'Loja Dev',

  'app.notifications.forum.registered':
    'Você foi adicionado(a) a comunidade de parceiros e clientes da Gertec. Aproveite e acesse agora mesmo clicando no link',
  'app.notifications.forum.welcome': 'Bem-vindo(a) ao Fórum Gertec',
  'app.notifications.formProfileUpdated.title': 'Trouxemos novidades =)',
  'app.notifications.formProfileUpdated.description':
    'O formulário do Perfil foi atualizado. Por favor, complete os dados do seu endereço.',

  'dashboard.title.welcome': 'Bem-vindo(a) ao',
  'dashboard.title.subtitle': 'Portal do Desenvolvedor',
  'dashboard.title.greeting': 'O que você gostaria de acessar hoje ?',
  'dashboard.cards.api.title': 'APIs, SDKs e Suporte',
  'dashboard.cards.api.description':
    'APIs e SDKs com documentações atualizadas com suporte especializado para auxiliá-lo e esclarecer dúvidas.',
  'dashboard.cards.forum.title': 'Fórum e Comunidades',
  'dashboard.cards.forum.description': 'Fórum de discussão disponível para os desenvolvedores',
  'dashboard.cards.trainings.title': 'Treinamentos',
  'dashboard.cards.trainings.description': 'Recursos para ajudar profissionais a desenvolverem soluções',
  'dashboard.cards.warning.soon': 'EM BREVE',
  'dashboard.image.alt': 'Homem sentado usando um notebook sobre uma mesa',
  'dashboard.footer.title': 'Conheça nossos serviços:',
  'dashboard.footer.card.smartstore.description': 'Loja de Aplicativos Gertec',
  'dashboard.footer.card.mymdm.description': 'Plataforma de monitoramento e gestão de terminais',

  'errors.ERROR-USER-1002': 'Emails a seguir já foram convidados ou cadastrados: ',
  'errors.ERROR-USER-1015': 'Não foi possível criar o usuário no Jira',
  'errors.ERROR-USER-1020': 'Você não tem permissão',
  'errors.ERROR-OGNZ-1002': 'Não foi possível associar time ao Jira',
  'errors.ERROR-OGNZ-1003': 'Operação não realizada. Time já cadastrado',
  'errors.ERROR-CMP-1003': 'Campos obrigatórios',
  'errors.ERROR-COMMON-1002': 'Excedeu o limite 255 caracteres',
  'errors.ERROR-COMMON-1000': 'Erro desconhecido',
  'errors.ERROR-JIRA-USER-1005': 'Busca inválida',
  'errors.ERROR-JIRA-USER-1006': 'Você não tem permissão para acessar este conteúdo',
  'errors.ERROR-MGT-1001': 'Empresa não encontrada no Jira',
  'errors.ERROR-MGT-1002': 'Nome de empresa é obrigatório',
  'errors.ERROR-MGT-1003': 'CNPJ é inválido',
  'errors.ERROR-MGT-1004': 'Nome do responsável é obrigatório',
  'errors.ERROR-MGT-1005': 'E-mail é inválido',
  'errors.ERROR-MGT-1006': 'Telefone é inválido',
  'errors.ERROR-MGT-1007': 'Domínio é obrigatório',
  'errors.ERROR-MGT-1008': 'CNPJ está duplicado no arquivo enviado',
  'errors.ERROR-MGT-1009': 'E-mail está duplicado no arquivo enviado',
  'errors.ERROR-MGT-1010': 'Este e-mail já foi cadastrado',
  'errors.ERROR-MGT-1011': 'Este CNPJ já foi cadastrado',
  'errors.unknownError': 'Erro desconhecido',

  'request.input.search.placeholder': 'A solicitação contém',

  'services.view.publishing.subtitle': 'Disponibilize seus aplicativos remotamente para os terminais',
  'services.view.publishing.paragraph.1':
    'Após desenvolvimento da aplicação, sua empresa precisará publicá-lo na Smart Store ou MDM da Gertec.',
  'services.view.publishing.paragraph.2':
    'Abaixo disponibilizamos as orientações necessárias para solicitar o cadastro do ambiente para o seu negócio!',
  'services.view.publishing.flow.1.title': 'Desenvolvimento da Aplicação',
  'services.view.publishing.flow.1.description':
    'Ao finalizar o desenvolvimento da sua aplicação, chegou a hora de disponibilizar para o seu Cliente',
  'services.view.publishing.flow.2.title': 'Cadastro SmartStore ou MDM',
  'services.view.publishing.flow.2.requestAccess': 'Solicitar Cadastro',
  'services.view.publishing.flow.2.acceptTerms': 'Termos de aceite',
  'services.view.publishing.flow.2.joiningTerms': 'Termos de adesão',
  'services.view.publishing.flow.2.signature': 'Assinatura de contratos',
  'services.view.publishing.flow.2.robotAccess': 'Acesso ao Robô de Assinatura + QR Code',
  'services.view.publishing.flow.2.robotHowTo': 'Instruções de uso do Robô',
  'services.view.publishing.flow.3.title': 'Publicação na Smart Store ou MDM',
  'services.view.publishing.flow.3.description':
    'Todas as etapas concluídas? Chegou a hora de disponibilizar a sua aplicação na Smart Store ou MDM.',
  'services.view.publishing.flow.3.howToPublishSmartStore': 'Como publicar na SmartStore',
  'services.view.publishing.flow.3.howToPublishMDM': 'Como publicar no MDM',

  'teams.table.description': 'Descrição',
  'teams.view.subtitle': 'Gerenciar equipes de trabalho',
  'teams.button.time': 'Time',
  'teams.header.title': 'Cadastrar time',
  'teams.input.title.placeholder': 'Ex.: Suporte, Desenvolvedores e etc...',
  'teams.input.description.placeholder': 'Ex.: Time responsável por gerenciar chamados...',
  'teams.filter.placeholder.searchTeam': 'Buscar time',
  'teams.modal.delete.title': 'Deletar time',
  'teams.modal.delete.message': 'Você tem certeza que deseja deletar o time {{team}}?',

  'device.management': 'Administração',
  'device.modal.delete.title': 'Excluir dispositivo',
  'device.modal.delete.message': 'Confirme a exclusão do device {{name}}',
  'device.label.saved': 'Device cadastrado',
  'device.label.destroy': 'Device excluído',

  'companies.view.subtitle':
    'Gerencie suas informações para que Portal do Desenvolvedor Gertec atenda suas necessidades',
  'companies.view.info.general.title': 'Informações Básicas',
  'companies.view.info.general.idDocument': 'CNPJ/TAXID',
  'companies.view.info.general.segment': 'Segmento',
  'companies.view.info.personal.title': 'Informações do Responsável',
  'companies.view.info.personal.name': 'Responsável',
  'companies.view.info.personal.email': 'Email',
  'companies.view.info.personal.domain': 'Domínio',
  'companies.view.info.personal.phone': 'Telefone',
  'companies.view.info.personal.phonePlaceholder': '(XX) XXXX-XXXX',
  'companies.view.info.personal.address': 'Endereço',
  'companies.view.info.personal.zipCode': 'CEP',
  'companies.view.info.personal.zipCodePlaceholder': 'XXXXX-XXX',
  'companies.view.info.personal.search': 'Buscar',
  'companies.view.info.personal.publicPlace': 'Logradouro',
  'companies.view.info.personal.publicPlacePlaceholder': 'Digite o nome da avenida ou rua',
  'companies.view.info.personal.number': 'Número',
  'companies.view.info.personal.numberPlaceholder': 'Digite o número',
  'companies.view.info.personal.complements': 'Complemento',
  'companies.view.info.personal.complementsPlaceholder': 'Casa, apartamento, sala, etc',
  'companies.view.info.personal.optional': 'Opcional',
  'companies.view.info.personal.district': 'Bairro',
  'companies.view.info.personal.districtPlaceholder': 'Digite o nome do bairro',
  'companies.view.info.personal.state': 'Estado',
  'companies.view.info.personal.city': 'Cidade',
  'companies.view.section.iWouldLike': 'Eu gostaria de:',
  'companies.view.section.ndaRequired': 'Necessário envio do NDA:',
  'companies.checkbox.title.automationSolutionsFromGertec': 'Soluções de automação da Gertec',
  'companies.checkbox.title.useMyStoreSystemGertecPinPad': 'Integrar sistema de loja com um PIN pad da Gertec',
  'companies.checkbox.title.smartPosByGertec': 'Desenvolver para Android / Smart POS da Gertec',
  'companies.checkbox.title.publishAnApptoMDMorSmartStore': 'Publicar um aplicativo no MDM ou SmartStore',
  'companies.checkbox.title.acceptNewsletter': 'Aceito  receber e-mails com oportunidades e novidades da Gertec',
  'companies.checkbox.title.developOthers': 'Desenvolver para outros produtos não especificados anteriormente',
  'companies.checkbox.title.developPadIntegration': 'Preciso de Suporte Especializado para integração com PIN pad',
  'companies.checkbox.title.developPosApplication': 'Desejo desenvolver aplicações para POS',
  'companies.checkbox.example.automationSolutionsFromGertec': '(ex. Integrar impressora, SAT, Busca Preço, MFE)',
  'companies.checkbox.example.useMyStoreSystemGertecPinPad': '(ex. Integrar frente de caixa com TEF)',
  'companies.checkbox.example.smartPosByGertec': '(ex. Integrar ERP com TEF)',
  'companies.checkbox.example.publishAnApptoMDMorSmartStore': '(ex. aplicativos para GPOS700)',
  'companies.checkbox.example.developOthers': 'Obrigatório envio do NDA',
  'companies.checkbox.example.developPadIntegration': 'Obrigatório envio do NDA',
  'companies.checkbox.example.developPosApplication': 'Obrigatório envio do NDA',
  'companies.link.nda.document': 'Enviar NDA - Acordo de Confidencialidade',
  'companies.button.nda.clickHere': 'Clique aqui',
  'companies.text.nda': 'para acessar o NDA novamente',
  'companies.screen.disable.title': 'Tela em atualização',
  'companies.screen.disable.subtitle': 'Em breve estará disponivel para acesso.',

  'companies.button.save.loading': 'Salvando',
  'components.select.noOptions': 'Sem opções',
  'components.modal.title': 'Atualizar informações',
  'components.modal.title.nda': 'Enviar NDA',
  'components.modal.discard': 'Todas as alterações não salvas serão perdidas.\nContinuar?',
  'components.modal.discard.nda': 'Ao cancelar, todas as informações adicionadas serão perdidas. Continuar?',
  'components.modal.update': 'Você deseja salvar todas as alterações?',
  'components.modal.reason': 'Justificativa',
  'components.modal.justifyHere': 'Justifique aqui',
  'components.customSelect.clearSelection': 'Limpar seleção',
  'components.customSelect.xOfy': '{{quantity}} de {{total}}',
  'components.alert.success.save': 'Dados atualizados com sucesso',

  'form.required.number': 'Campo deve ser um número válido',
  'form.invalid.domain': 'Precisa inserir um domínio válido ex: sua-empresa.com',
  'form.invalid.telephone': 'Telefone inválido',
  'form.invalid.role': 'Cargo Inválido',
  'form.invalid.teams': 'Times Inválidos',
  'form.invalid.cep': 'CEP inválido',
  'form.invalid.cepNotFound': 'CEP não encontrado. Você pode preencher manualmente',
  'form.invalid.maxLength': 'Deve ser menor ou igual à {{max}} caracteres',

  'clients.header.subtitle': 'Lista de empresas cadastradas',
  'clients.table.representative': 'Representante',
  'clients.table.register': 'Cadastro',
  'clients.table.telephone': 'Telefone',
  'clients.table.corporateName': 'Razão Social',
  'clients.filter.search.placeholder': 'Buscar por CNPJ, Razão Social ou Representante',

  'clients.months.sunday': 'D',
  'clients.months.monday': 'S',
  'clients.months.tuesday': 'T',
  'clients.months.wednesday': 'Q',
  'clients.months.thursday': 'Q',
  'clients.months.friday': 'S',
  'clients.months.saturday': 'S',

  'clients.months.january': 'Janeiro',
  'clients.months.february': 'Fevereiro',
  'clients.months.march': 'Março',
  'clients.months.april': 'Abril',
  'clients.months.may': 'Maio',
  'clients.months.june': 'Junho',
  'clients.months.july': 'Julho',
  'clients.months.august': 'Agosto',
  'clients.months.september': 'Setembro',
  'clients.months.october': 'Outubro',
  'clients.months.november': 'Novembro',
  'clients.months.december': 'Dezembro',

  'dashboard.label.callsigns.companies': 'Empresas cadastradas',
  'dashboard.label.callsigns.users': 'Usuários cadastrados',
  'dashboard.label.callsigns.active': 'Ativos',
  'dashboard.label.callsigns.tooltip.companies.title': 'empresas',
  'dashboard.label.callsigns.tooltip.users.title': 'administradores e desenvolvedores',
  'dashboard.label.callsigns.tooltip':
    'Quantidade de {{title}} que acessaram o portal ao menos uma vez no período selecionado.',
  'dashboard.label.callsigns.segments.tooltip':
    'Ranking dos Segmentos que possuem maior número de empresas cadastradas.',
  'dashboard.label.callsigns.visits': 'Acessos únicos realizados (login) no período selecionado.',
  'dashboard.label.segments': 'TOP 5 - Segmentos',
  'dashboard.label.visits': 'Visitas',
  'dashboard.label.registeredUsers': 'Usuários cadastrados',
  'dashboard.label.frequencyAccess': 'Frequência de acesso',
  'dashboard.label.others': 'Outros',
  'dashboard.label.currentPeriod': 'Período Atual',
  'dashboard.label.previousPeriod': 'Período anterior',
  'dashboard.date.checkbox.label': 'Comparar com o mesmo período anterior',
  'dashboard.date.checkbox.tooltip': 'Compare as informações de um período anterior equivalente',
  'dashboard.date.select.option1': '24 horas',
  'dashboard.date.select.option2': '7 dias',
  'dashboard.date.select.option3': '14 dias',
  'dashboard.date.select.option4': '30 dias',
  'dashboard.date.select.option5': '6 meses',
  'dashboard.date.select.option6': 'Último ano',
  'dashboard.chartError': 'Ops! Não foi possível carregar os dados. Tente recarregar a página',

  'footer.privacyPolicy': 'Política de privacidade',
  'footer.termsConditions': 'Termos e Condições',
  'footer.support': 'Suporte',
  'footer.copyright': '© {{year}} Portal do Desenvolvedor',
  'footer.gertec': 'Gertec',

  'slide_modal.title': 'Boas vindas ao Portal do Desenvolvedor',
  'slide_modal.description':
    'Para que possamos direcionar soluções personalizadas e agregar mais valor para os seus clientes, participe da nossa pesquisa e nos ajude a entender as suas necessidades.',
  'slide_modal.button_start': 'Começar',
  'slide_modal.tooltip': 'Por favor, responda nossa pesquisa.',

  ...keysRequestPtBr,
  ...keysNdasPtBr,
  ...keysMigratesPtBr,
  ...keysClientPtBr,
  ...keysSurveyPtBr,
  ...keysUserPtBr,
  ...keysDocumentPtBr,
  ...keysServicesPtBr,

  // intlDefaultFormatter
  intlDateTime: '{{date, datetime}}'
};
